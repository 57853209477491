import {
  LOAD_ADMIN_SITES,
  SET_FILTERS_ADMIN_SITES,
  RESET_ADMIN_SITES,
} from '../../config/actions';

export default (
  state = {
    searchTerm: '',
    limit: 10,
    offset: 0,
    sortOrder: 'asc',
    data: null,
    pageInfo: { count: 0 },
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ADMIN_SITES:
      return Object.assign({}, state, { ...payload });

    case SET_FILTERS_ADMIN_SITES:
      return Object.assign({}, state, {
        limit: payload.limit,
        offset: payload.offset,
      });
    case RESET_ADMIN_SITES:
      return Object.assign({}, state, {
        searchTerm: '',
        limit: 10,
        offset: 0,
        sortOrder: 'asc',
        data: null,
        pageInfo: { count: 0 },
      });
    default:
      return state;
  }
};
