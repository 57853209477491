import {
  APPEND_SITE_ERROR_ELEMENTS,
  LOAD_SITE_ERROR,
  LOAD_SITE_ERROR_ELEMENTS,
  RESET_SITE_ERROR,
  SET_SITE_ERROR_LOADER,
} from "../../config/actions";

export default (
  state = { issue: null, isLoading: true },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SITE_ERROR_LOADER:
      return Object.assign({}, state, { isLoading: true });
    case LOAD_SITE_ERROR:
      return Object.assign({}, state, {
        issue: payload.issue,
        isLoading: false,
      });
    case RESET_SITE_ERROR:
      return Object.assign({}, state, {
        issue: null,
        violations: null,
        isLoading: false,
      });

    case LOAD_SITE_ERROR_ELEMENTS:
      // return Object.assign({}, state, {
      //   pageElements: processErrorElements(
      //     payload.pageElements,
      //     payload.oldPageElements
      //   ),
      //   oldPageElements: payload.oldPageElements,
      //   isLoading: false,
      // });
      return Object.assign({}, state, {
        violations: payload,
        isLoading: false,
      });
    case APPEND_SITE_ERROR_ELEMENTS:
      return Object.assign({}, state, {
        violations: [...(state.violations || []), ...payload],
        isLoading: false,
      });

    default:
      return state;
  }
};
