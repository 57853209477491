import React, { Fragment, useRef, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ProgressBar.module.scss";

const ProgressBar = (props) => {
  // const { message = "Please wait..." } = props;
  const container = useRef(null);
  const [position, setPosition] = useState({});
  const { display, percent, message,  } = useSelector((state) => state.progress);

  useLayoutEffect(() => {
    if (display) {
      const containerElDimension = container.current.getBoundingClientRect();
      setPosition({
        style: {
          top: `calc(10% + ${document.body.scrollTop}px)`,
          left: `calc(50% - ${containerElDimension.width / 2}px)`,
        },
      });
    }
  }, [display]);

  const renderProgressBar = () => {
    if (display) {
      return (
        <Fragment>
          <div className={styles.overlay} />
          <div className={styles.container} ref={container} {...position}>
            {message && <div className={styles.message}>{message}</div>}
            {percent !== '' && (<div className={styles.progress}>
              <span style={{ width: `${percent}%` }}>{`${percent}%`}</span>
            </div>)}
          </div>
        </Fragment>
      );
    }

    return null;
  };

  return ReactDOM.createPortal(renderProgressBar(), document.body);
};

export default ProgressBar;
