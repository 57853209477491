import "./App.scss";

import AppRoutes from "./routes";
import Notification from "./components/Notification";
import ProgressBar from "./components/ProgressBar";
import React from "react";
import ErrorHandler from "./ErrorHandler";

function App() {
  return (
    <div className="app">
      <ErrorHandler>
        <Notification />
        <AppRoutes />
        <ProgressBar />
      </ErrorHandler>
    </div>
  );
}

export default App;
