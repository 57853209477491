import {
  LOAD_SITE,
  LOAD_SITE_ERRORS_VIOLATION,
  LOAD_SITE_STATS_VIOLATION,
  RESET_SITE
} from "../../config/actions";

export default (state = { site: null, stats: null, errors: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SITE:
      return Object.assign({}, state, { site: payload.data });
    case LOAD_SITE_STATS_VIOLATION:
      return Object.assign({}, state, { stats: payload });
    case LOAD_SITE_ERRORS_VIOLATION:
      return Object.assign({}, state, { errors: payload });
    case RESET_SITE:
      return Object.assign({}, state, {
        site: null,
        stats: null,
        errors: null,
      });

    default:
      return state;
  }
};
