import { set, get, findIndex } from 'lodash';
import {
  LOAD_MANUAL_REPORT_DETAILS,
  LOAD_MANUAL_REPORT_ERROR,
  LOAD_MANUAL_REPORT_ERRORS,
  RESET_MANUAL_REPORT_ERROR,
  UPDATE_MANUAL_REPORT_ERROR_FORM,
  RESET_MANUAL_REPORT_ERROR_FORM,
  UPDATE_MANUAL_REPORT_ERRORS,
  UPDATE_MANUAL_REPORT_ERROR_STATUS,
  SET_MANUAL_REPORT_FOCUSABLE_ERROR_ID,
  TOGGLE_ISSUE_COMMENT_EDITOR,
  TOGGLE_ISSUE_STATUS_EDITOR,
} from '../../config/actions';

export default (
  state = {
    reportDetails: {},
    reportErrors: null,
    reportError: { priority: 'critical' },
    uploadProgressPercent: 0,
    focusableErrorId: null,
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_MANUAL_REPORT_ERRORS:
      return Object.assign({}, state, { ...payload });
    case RESET_MANUAL_REPORT_ERROR:
      return Object.assign({}, state, {
        reportErrors: null,
      });
    case LOAD_MANUAL_REPORT_DETAILS:
      return Object.assign({}, state, { ...payload });
    case LOAD_MANUAL_REPORT_ERROR:
      return Object.assign({}, state, { ...payload });
    case UPDATE_MANUAL_REPORT_ERROR_FORM:
      return Object.assign({}, state, {
        reportError: { ...state.reportError, ...(payload || {}) },
      });
    case RESET_MANUAL_REPORT_ERROR_FORM:
      return Object.assign({}, state, { reportError: {} });

    case UPDATE_MANUAL_REPORT_ERRORS:
      return set(state, `reportErrors[${payload.index}]`, payload.value);

    case SET_MANUAL_REPORT_FOCUSABLE_ERROR_ID:
      return Object.assign({}, state, {
        focusableErrorId: payload.focusableErrorId,
      });
    case UPDATE_MANUAL_REPORT_ERROR_STATUS:
      const error = get(state, `reportError`, {});
      const updatedError = set(error, `status`, parseInt(payload.status, 10));

      const errors = get(state, `reportErrors`, []);
      const errorIndex = findIndex(errors, (err) => err.id === error._id);
      const updatedErrors = set(
        errors,
        `[${errorIndex}].status`,
        parseInt(payload.status, 10)
      );

      return Object.assign({}, state, {
        reportError: updatedError,
        reportErrors: updatedErrors,
      });
    case TOGGLE_ISSUE_COMMENT_EDITOR:
      const issues = get(state, `reportErrors`, []);
      const updatedIssues = issues.map((issue, idx) => {
        if (payload.index === idx) {
          return {
            ...issue,
            commentEditor: !get(issue, `commentEditor`, false),
          };
        }

        return { ...issue, commentEditor: false };
      });

      return Object.assign({}, state, {
        reportErrors: updatedIssues,
      });

    case TOGGLE_ISSUE_STATUS_EDITOR:
      const rows = get(state, `reportErrors`, []);
      const updatedRows = rows.map((issue, idx) => {
        if (payload.index === idx) {
          return {
            ...issue,
            statusEditor: !get(issue, `statusEditor`, false),
            commentEditor: false,
          };
        }

        return { ...issue, statusEditor: false, commentEditor: false };
      });

      return Object.assign({}, state, {
        reportErrors: updatedRows,
      });
    default:
      return state;
  }
};
