import {
  LOAD_COMMENTS,
  RESET_COMMENTS,
  UPDATE_COMMENT_READ,
} from "../../config/actions";
import { get } from "lodash";

const unreadCount = (comments = []) =>
  comments.reduce((sum, next) => (sum += !next.read ? 1 : 0), 0);

export default (state = { comment: {}, unreadCommentCount: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_COMMENTS:
      return Object.assign({}, state, {
        comment: { ...state.comment, [payload.issueId]: payload.comments },
        unreadCommentCount: {
          ...state.unreadCommentCount,
          [payload.issueId]: unreadCount(payload.comments),
        },
      });
    case RESET_COMMENTS:
      return Object.assign({}, state, {
        comment: {},
        unreadCommentCount: {},
      });
    case UPDATE_COMMENT_READ:
      const comments = [...get(state, `comment[${payload.issueId}]`, [])];
      const commentIds = payload.commentIds;
      const updatedComments = comments.map((comment) => {
        if (commentIds.includes(comment._id)) {
          return { ...comment, read: true };
        }
        return comment;
      });

      return Object.assign({}, state, {
        comment: { ...state.comment, [payload.issueId]: updatedComments },
        unreadCommentCount: {
          ...state.unreadCommentCount,
          [payload.issueId]: unreadCount(updatedComments),
        },
      });
    default:
      return state;
  }
};
