import React from "react";
import styles from "./UIError.module.scss";

const UIErrorPage = (props) => (
  <div style={{ textAlign: "center" }}>
    <h1 className={styles.h1}>500</h1>
    <h2 className={styles.h2}>
      Unexpected Error <b>:(</b>
    </h2>

    <div class={styles.gears}>
      <div class={`${styles.gear} ${styles.one}`}>
        <div class={styles.bar}></div>
        <div class={styles.bar}></div>
        <div class={styles.bar}></div>
      </div>
      <div class={`${styles.gear} ${styles.two}`}>
        <div class={styles.bar}></div>
        <div class={styles.bar}></div>
        <div class={styles.bar}></div>
      </div>
      <div class={`${styles.gear} ${styles.three}`}>
        <div class={styles.bar}></div>
        <div class={styles.bar}></div>
        <div class={styles.bar}></div>
      </div>
    </div>
    <div style={{ textAlign: "center" }}>
      <a href="/">Return to home page</a>
    </div>
  </div>
);

export default UIErrorPage;
