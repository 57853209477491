import {
  LOAD_DASHBOARD_SITES,
  LOAD_DASHBOARD_SITES_STATS,
  LOAD_OWN_SITES_LIST,
  RESET_DASHBOARD_SITES,
  RESET_DASHBOARD_SITES_STATS
} from "../../config/actions";

export default (state = { sites: null, stats: null, siteList: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_OWN_SITES_LIST:
      return Object.assign({}, state, { siteList: payload });
    case LOAD_DASHBOARD_SITES:
      return Object.assign({}, state, { sites: payload });
    case LOAD_DASHBOARD_SITES_STATS:
      return Object.assign({}, state, { stats: payload });
    case RESET_DASHBOARD_SITES:
      return Object.assign({}, state, { sites: null });
    case RESET_DASHBOARD_SITES_STATS:
      return Object.assign({}, state, { stats: null });
    default:
      return state;
  }
};
