import account from "./account";
import adminSite from "./adminSite";
import adminUser from "./adminUser";
import adminTemplate from "./adminTemplate";
import { combineReducers } from "redux";
import dashboard from "./dashboard";
import errorPage from "./errorPage";
import { reducer as formReducer } from "redux-form";
import notification from "./notification";
import report from "./report";
import reportError from "./reportError";
import site from "./site";
import sites from "./sites";
import users from "./users";
import progress from "./progress";
import commentList from "./commentList";
import notifications from "./notifications";

export default combineReducers({
  notification,
  notifications,
  dashboard,
  sites,
  site,
  errorPage,
  users,
  account,
  adminUser,
  adminSite,
  adminTemplate,
  report,
  reportError,
  progress,
  commentList,
  form: formReducer
});
