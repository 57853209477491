import {
  LOAD_NOTIFICATIONS
} from "../../config/actions";

export default (
  state = {
    notifications: null
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_NOTIFICATIONS:
      return Object.assign({}, state, { ...payload });
    default:
      return state;
  }
};
