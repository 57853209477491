import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import {
  getAuthToken,
  getToken,
  setNavUrl,
  getNavUrl,
  clearNavUrl,
} from '../helpers/token';

import { AppContext } from '../context';
import { isEmpty } from 'lodash';

const TRACKING_ID = "G-PYVXXH8KPC";

const isAutheticated = () => {
  const token = getAuthToken();

  if (!isEmpty(token)) {
    return true;
  }

  return false;
};

export const isUserInRole = (role) => {
  const userInfo = getToken();
  if (!isEmpty(userInfo.token)) {
    return userInfo.role === role;
  }

  return false;
};

export const isStaff = () => {
  const userInfo = getToken();
  if (!isEmpty(userInfo.token)) {
    return userInfo.isStaff;
  }

  return false;
};

const getUserData = () => {
  const userInfo = getToken();
  if (!isEmpty(userInfo.token)) {
    return {
      role: userInfo.role,
      isStaff: userInfo.isStaff,
      isAuditor: userInfo.isAuditor,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
    };
  }

  return { role: '', isStaff: false };
};

const loginRedirect = () => {
  return <Redirect to={{ pathname: '/signin' }} />;
};

const AuthRoute = ({ title, component: Component, location, ...rest }) => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    document.title = `${title} - Inclusive Web`;
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: `${title} - Inclusive Web` });
  }, [title, location.pathname]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAutheticated()) {
          const friendlyUrl = getNavUrl();
          if (!isEmpty(friendlyUrl)) {
            clearNavUrl();
            return <Redirect to={{ pathname: friendlyUrl }} />;
          }
          return (
            <AppContext.Provider value={getUserData()}>
              <Component {...props} />
            </AppContext.Provider>
          );
        }

        setNavUrl(props.match.url);

        return loginRedirect();
      }}
    />
  );
};

export default AuthRoute;
