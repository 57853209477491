import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactDOM from "react-dom";
import { isEmpty } from "lodash";
import styles from "./Notification.module.scss";

const Notification = props => {
  const { success, error } = useSelector(state => ({
    success: state.notification.success,
    error: state.notification.error
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(success)) {
      const timer = setTimeout(
        () => dispatch({ type: "RESET_SUCCESS_MSG" }),
        5000
      );

      return () => clearTimeout(timer);
    }
  }, [success, dispatch]);

  const handleClose = e => {
    const { option } = e.currentTarget.dataset;
    if (option === "success") {
      dispatch({ type: "RESET_SUCCESS_MSG" });
    }

    if (option === "error") {
      dispatch({ type: "RESET_ERROR_MSG" });
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.notification}>
      {!isEmpty(success) && (
        <div className={`${styles.container} ${styles.success}`}>
          <button
            className={`${styles.button} ${styles.successClose}`}
            type="button"
            data-option="success"
            onClick={handleClose}
          >
            &#x2716;
          </button>
          {success.message}
        </div>
      )}
      {!isEmpty(error) && (
        <div className={`${styles.container} ${styles.error}`}>
          <button
            className={`${styles.button} ${styles.errorClose}`}
            type="button"
            data-option="error"
            onClick={handleClose}
          >
            &#x2716;
          </button>
          <ul className={styles.errorMessage}>
            <li>{error.message}</li>
            {!isEmpty(error.list) && (
              <li>
                <ul>
                  {error.list.map(err => (
                    <li key={err} className={styles.errorList}>
                      {err}
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>,
    document.body
  );
};

export default Notification;
