import {
  ADD_USER,
  DELETE_USER,
  LOAD_CURRENT_USER_DOMAINS,
  LOAD_CURRENT_USER_ROLES,
  LOAD_USERS,
  UPDATE_USER,
} from "../../config/actions";
import { findIndex, set } from "lodash";

export default (state = { users: null, sites: [], roles: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_USERS:
      return Object.assign({}, state, { users: payload });
    case ADD_USER:
      const itemIndex = findIndex(
        state.users,
        (u) => u.email === payload.email
      );
      if (itemIndex >= 0) {
        return Object.assign({}, state, {
          users: set(state.users, `[${itemIndex}]`, payload),
        });
      }

      return Object.assign({}, state, {
        users: [...(state.users || []), payload],
      });

    case UPDATE_USER:
      let users = (state.users || []).map((item) => {
        if (item._id === payload._id) {
          return payload;
        } else {
          return item;
        }
      });

      return Object.assign({}, state, {
        users,
      });

    case DELETE_USER:
      let remainingUsers = (state.users || []).filter(
        (item) => item._id !== payload._id
      );
      return Object.assign({}, state, {
        users: remainingUsers,
      });

    case LOAD_CURRENT_USER_DOMAINS:
      return Object.assign({}, state, { sites: payload });
    case LOAD_CURRENT_USER_ROLES:
      return Object.assign({}, state, { roles: payload });

    default:
      return state;
  }
};
