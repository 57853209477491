import {
  ADD_SITE,
  DELETE_SITE,
  LOAD_SITES,
  UPDATE_SITE
} from "../../config/actions";

export default (state = { sites: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SITES:
      return Object.assign({}, state, { sites: payload });

    case ADD_SITE:
      return Object.assign({}, state, {
        sites: [payload, ...(state.sites || [])]
      });

    case UPDATE_SITE:
      const { id, name, url } = payload;
      const updatedSites = (state.sites || []).map(s => {
        if (s.id === id) {
          return Object.assign({}, s, { name, url });
        }
        return s;
      });
      return Object.assign({}, state, { sites: updatedSites });

    case DELETE_SITE:
      const remainingSites = (state.sites || []).filter(
        s => s.id !== payload.id
      );
      return Object.assign({}, state, { sites: remainingSites });

    default:
      return state;
  }
};
