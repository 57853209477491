import {
  LOAD_ADMIN_TEMPLATES,
  SET_SELECTED_TEMPLATE,
  RESET_SELECTED_TEMPLATE,
} from "../../config/actions";
import { find } from "lodash";

export default (state = { templates: [], selectedTemplate: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ADMIN_TEMPLATES:
      return Object.assign({}, state, { templates: payload });

    case SET_SELECTED_TEMPLATE:
      const { id } = payload;
      const template = find(state.templates, (t) => t._id === id);
      return Object.assign({}, state, { selectedTemplate: template });

    case RESET_SELECTED_TEMPLATE:
      return Object.assign({}, state, { selectedTemplate: {} });

    default:
      return state;
  }
};
