import { applyMiddleware, createStore, compose } from "redux";

import reducers from "./reducers";
import thunk from "redux-thunk";
import logger from "redux-logger";

const appEnv = process.env.REACT_APP_ENV || "development";

function configureStore() {
  const middlewares = [thunk];

  if (appEnv === "development") {
    middlewares.push(logger);
  }
  const store = compose(applyMiddleware(...middlewares))(createStore)(reducers);
  return store;
}

const prevState = window.__PRELOADED_STATE__ || undefined;
export default configureStore(prevState);
