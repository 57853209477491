import React, { useEffect } from "react";

import { Route } from "react-router-dom";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-PYVXXH8KPC";

const AppRoute = props => {
  const { title, location, ...rest } = props;

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    document.title = `${title} - Inclusive Web`;
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: `${title} - Inclusive Web` });
  }, [title, location.pathname]);

  return <Route {...rest} />;
};

export default AppRoute;
