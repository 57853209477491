import {
  LOAD_ADMIN_USERS,
  SET_FILTERS_ADMIN_USERS,
  GET_ADMIN_USER_ROLE
} from "../../config/actions";

export default (
  state = {
    user: {},
    searchTerm: "",
    limit: 10,
    offset: 0,
    sortOrder: "asc",
    data: null,
    pageInfo: { count: 0 },
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ADMIN_USERS:
      return Object.assign({}, state, { ...payload });

    case SET_FILTERS_ADMIN_USERS:
      return Object.assign({}, state, {
        limit: payload.limit,
        offset: payload.offset,
      });

    case GET_ADMIN_USER_ROLE:
      return Object.assign({}, state, { user: payload.user });

    default:
      return state;
  }
};
