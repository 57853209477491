import {
  LOAD_ACCOUNT_PREFERENCES,
  LOAD_ACCOUNT_PROFILE
} from "../../config/actions";

export default (state = { preferences: [], profile: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ACCOUNT_PREFERENCES:
      return Object.assign({}, state, { preferences: payload });

    case LOAD_ACCOUNT_PROFILE:
      return Object.assign({}, state, { profile: payload });

    default:
      return state;
  }
};
