import React from "react";
import UIErrorPage from "./components/UIError";

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });
  }

  render() {
    return this.state.errorOccurred ? (
      <UIErrorPage />
    ) : (
      this.props.children
    );
  }
}

export default ErrorHandler;
