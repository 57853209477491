import { getItem, removeItem, setItem } from "./storage";

import { isEmpty } from "lodash";

const key = "inclusive_usr_info";
const navKey = "inclusive_frnd_fwd_url";

export const setToken = token => {
  setItem(key, token);
};

export const setNavUrl = url => {
  setItem(navKey, url);
};

export const getNavUrl = () => getItem(navKey);

export const clearNavUrl = () => {
  removeItem(navKey);
}

export const getToken = () => {
  return getItem(key);
};

export const getAuthToken = () => {
  const userInfo = getItem(key);
  if (!isEmpty(userInfo)) {
    return userInfo.token;
  }

  return "";
};

export const clearToken = () => {
  removeItem(key);
};
