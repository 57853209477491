import {
  SET_PROGRESS_TOGGLE,
  SET_PROGRESS_PERCENT,
} from "../../config/actions";

export default (
  state = {
    display: false,
    percent: '',
    message: "",
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROGRESS_TOGGLE: {
      return Object.assign({}, state, {
        display: payload.display,
        percent: payload.percent,
        message: payload.message,
      });
    }

    case SET_PROGRESS_PERCENT: {
      return Object.assign({}, state, {
        percent: payload.percent,
      });
    }

    default:
      return state;
  }
};
